import React from 'react';
import ReactDOM from 'react-dom';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import './assets/styles/app.scss';
import './assets/styles/style.css';
import * as serviceWorker from './serviceWorker';
import Amplify, { Auth } from 'aws-amplify';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'

const advancedMatching = { };
const options = {
  autoConfig: true,
  debug: false
};
ReactPixel.init('564671181400579', advancedMatching, options);
ReactPixel.pageView();

const tagManagerArgs = {
    gtmId: 'GTM-TCDVZBS'
}

TagManager.initialize(tagManagerArgs)

Amplify.configure({
  "aws_project_region": process.env.REACT_APP_REGION,
  "aws_cognito_identity_pool_id": process.env.REACT_APP_IDENTITY_POOL_ID,
  "aws_cognito_region": process.env.REACT_APP_REGION,
  "aws_user_pools_id": process.env.REACT_APP_USER_POOL_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_APP_CLIENT_ID,
  "oauth": {},
  "aws_cloud_logic_custom": [
    {
      "name": "buzzemInApi",
      "endpoint": process.env.REACT_APP_API_URL,
      "region": process.env.REACT_APP_REGION,
      custom_header: async () => {
        return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
      }
    }
  ]
});

ReactDOM.render(
  <React.StrictMode>
    
    <BrowserRouter>
      <ReactNotification />
      <Router />
    </BrowserRouter>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
