import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthorizedLayout from '../shared/AuthorizedLayout';
import { Auth } from "aws-amplify";
import { Container, Row, Col, Button, Form, Card } from 'react-bootstrap';
import Loading from '../shared/Loading';
import { showNotification } from '../shared/Notification';

const Profile = () => {

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(function(currentUser) {
      if(currentUser.attributes.email_verified !== true) {
        //console.log("entre en verified false");
        //console.log("email:", currentUser.attributes.email);
        //console.log("email verified:", currentUser.attributes.email_verified);
        setCodeSent(true); // show the confirmation code form
      }
    });
    document.title = "Buzzem.in - My Profile";
  });

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const defaultPasswordData = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };
  const [changePassword, setChangePassword] = useState(defaultPasswordData);

  const minPasswordLength = 10;
  const minPasswordMessage = "Password must be at least " + minPasswordLength + " characters long"

  const handlePassValidate = (event) => {
    event.preventDefault();
    if (
      changePassword.oldPassword.length >= minPasswordLength &&
      changePassword.newPassword.length >= minPasswordLength &&
      changePassword.confirmPassword.length >= minPasswordLength &&
      changePassword.newPassword === changePassword.confirmPassword) {
      handlePassSubmit(event);
    } else {
      if (changePassword.newPassword !== changePassword.confirmPassword) {
        showNotification("Warning", "New password & Confirm password must be identical", "danger");
      } else {
        showNotification("Warning", minPasswordMessage, "danger");
      }
    }
  };

  const handlePassChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setChangePassword({
      ...changePassword,
      [name]: value
    });
  };

  async function handlePassSubmit(event) {
    setIsLoading(true);
    event.preventDefault();

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        changePassword.oldPassword,
        changePassword.newPassword
      );
      setErrors({});
      setIsLoading(false);
      showNotification("Success", "Password has been changed successfully", "success");
      history.push("/app");
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setErrors(error.response.data.errors);
        console.log(error.response.data.errors);
        showNotification("Error", errors, "danger");
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        showNotification("Error", error.message, "danger");
      }
      console.log(error.config);
    }
  }

  const [codeSent, setCodeSent] = useState(false);
  const [isConfirmingEmail, setIsConfirmingEmail] = useState(false);
  const [isSendingEmailCode, setIsSendingEmailCode] = useState(false);
  const defaultEmailData = {
    email: '',
    code: ''
  }
  const [changeEmail, setChangeEmail] = useState(defaultEmailData);

  const handleEmailChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setChangeEmail({
      ...changeEmail,
      [name]: value
    });
  };

  function validateEmailForm() {
    return changeEmail.email.length > 3;
  }

  function validateEmailCodeForm() {
    return changeEmail.code.length > 3;
  }

  async function handleEmailSubmit(event) {
    event.preventDefault();
    setIsSendingEmailCode(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { email: changeEmail.email });
      setCodeSent(true);
    } catch (error) {
      setCodeSent(false);
      setIsSendingEmailCode(false);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setErrors(error.response.data.errors);
        console.log(error.response.data.errors);
        showNotification("Error", errors, "danger");
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    }
  }

  async function handleCodeSubmit(event) {
    event.preventDefault();
    setIsConfirmingEmail(true);

    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", changeEmail.code);
      setCodeSent(false);
      setIsConfirmingEmail(false);
      showNotification("Success", "Email has been changed successfully", "success");
      history.push("/app");
    } catch (error) {
      setCodeSent(true);
      setIsConfirmingEmail(false);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setErrors(error.response.data.errors);
        console.log(error.response.data.errors);
        showNotification("Error", errors, "danger");
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    }
  }

  function renderUpdateEmailForm() {
    return (
      <Form onSubmit={(event) => { if(window.confirm('Are you sure?')){ handleEmailSubmit(event) } }}>
        { isConfirmingEmail && <Loading/> }
        <Form.Group>
          <Form.Label srOnly>Email</Form.Label>
          <Form.Control
            name="email"
            type="email"
            placeholder="Enter your new email"
            required
            onChange={handleEmailChange}
            value={changeEmail.email}
          />
          <div className="form-text small text-muted">
            A confirmation code will be sent to your new email.
          </div>
        </Form.Group>
        <div className="text-right">
          <Button className="btn-md" type="submit" disabled={!validateEmailForm()}>Send Code</Button>
        </div>
      </Form>
    );
  }

  function renderConfirmationEmailForm() {
    return (
      <Form onSubmit={handleCodeSubmit} noValidate>
        { isSendingEmailCode && <Loading/> }
        <Form.Group>
          <Form.Label srOnly>Confirmation Code</Form.Label>
          <Form.Control
            name="code"
            type="tel"
            placeholder="Enter your confirmation code"
            required
            onChange={handleEmailChange}
            value={changeEmail.code}
          />
          <div className="form-text small text-muted">
            Please check your email ({changeEmail.email}) for the confirmation code.
          </div>
        </Form.Group>
        <div className="text-right">
          <Button className="btn-md" type="submit" disabled={!validateEmailCodeForm()}>Check & Save</Button>
        </div>
      </Form>
    );
  }

  return (
    <AuthorizedLayout>
      <Container className="py-4 py-md-5">
        <h1 className="h1 mb-4">User Profile</h1>
        <Row>
          <Col md={6} className="col-12 mb-4">
            <Card>
              <Card.Body>
                <h2 className="h2">Change your Password</h2>
                <Form onSubmit={handlePassValidate} noValidate>
                  { isLoading && <Loading/> }
                  <Form.Group>
                    <Form.Label srOnly>Current Password</Form.Label>
                    <Form.Control
                      name="oldPassword"
                      type="password"
                      placeholder="Enter your current password"
                      required
                      onChange={handlePassChange}
                      value={changePassword.oldPassword}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label srOnly>New Password</Form.Label>
                    <Form.Control
                      name="newPassword"
                      type="password"
                      placeholder="Enter your new password"
                      required
                      onChange={handlePassChange}
                      value={changePassword.newPassword}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label srOnly>Confirm New Password</Form.Label>
                    <Form.Control
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirm your new password"
                      required
                      onChange={handlePassChange}
                      value={changePassword.confirmPassword}
                    />
                  </Form.Group>
                  <p className="small-help text-muted mb-3">
                    <strong>Password requirements</strong><br />
                    Min. 10 characters incl. one uppercase, one lowercase, one number and one symbol.
                  </p>
                  <div className="text-right">
                    <Button className="btn-md" type="submit" disabled={isLoading}>Save Password</Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="col-12 mb-4">
            <Card>
              <Card.Body>
                <h2 className="h2">Change your Email</h2>
                {!codeSent ? renderUpdateEmailForm() : renderConfirmationEmailForm()}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </AuthorizedLayout>
  );
};

export default Profile;
