import React from 'react';

const today = new Date();

const AppFooter = () => (
  <footer id="db-footer">
    <div className="footer-bottom-bar">
      <span>&copy; {today.getFullYear()}. Buzzem.in - All Rights Reserved.</span>
    </div>
  </footer>
);

export default AppFooter;
