import React from 'react';
import Header from './Header';
import Footer from './Footer';
import ScrollHandler from './ScrollHandler';

const Layout = ({ children }) => (
  <>
    <ScrollHandler />
    <Header />
    <main>
      { children }
    </main>
    <Footer />
  </>
);

export default Layout;
