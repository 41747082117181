import React from 'react';
import loadingLogo from '../../assets/images/buzzem.in-loader.svg';


const Loading = () => (
  <div className="loading">
    <img className="loading-logo" src={loadingLogo} alt="Loading" />
  </div>
);

export default Loading;
