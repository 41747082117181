import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../contexts/AppProvider';
import Loading from '../shared/Loading';
import SignupFlow from '../signupFlow/SignupFlow';
import Dashboard from '../dashboard/Dashboard';
import API from '../../utils/api';

export const getUser = async () => {
  return await API.get('/users');
};

const AppFlow = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [appContext, setAppContext] = useContext(AppContext);
  // { termsAndConditions: false, billing: false, payment: false, phoneNumber: "" }
  //const isContextLoaded = Object.keys(appContext).length;
  const isContextLoaded = !isLoading;

  useEffect(() => {
    getUser().then(data => {
      setAppContext(data);
      //console.log("data: ", data);
      setIsLoading(false);
    })
  }, [setAppContext]);

  return (
    <>
      { isLoading && <Loading/> }
      { isContextLoaded ? (
        appContext.free ? 
        <Dashboard/> : <SignupFlow/>
      ) : ('')
      }
    </>
  )
}

export default AppFlow;
