import React, { useEffect } from 'react';
import Layout from '../components/shared/Layout';
import { Container, Row, Col } from 'react-bootstrap';
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = { };
const options = {
  autoConfig: true,
  debug: false
};
ReactPixel.init('564671181400579', advancedMatching, options);
ReactPixel.pageView();

const headerImagesQty = 5;

function randomHeaderImage() {
	const headerContainer = document.querySelector("#hero-page");
	if (headerContainer.classList.length === 2) {
		let headerImageClass = "header-img-" + Number(Math.floor(Math.random() * headerImagesQty) + 1);
		headerContainer.classList.add(headerImageClass);
	}
}

const Faq = () => {

  useEffect(() => {
		randomHeaderImage();
    document.title = "Buzzem.in - Frequently Asked Questions";
  });

  return (
    <Layout>
			<section id="hero-page" className="header-hero header-hero-pages">
				<div className="overlay"></div>
				<Container>
					<div className="db-intro">
						<div className="db-intro-text">
							<div className="db-center-position">
								<h1>FAQ</h1>
							</div>
						</div>
					</div>
				</Container>
			</section>
			<section id="db-faqs">
				<Container>
					<Row className="row-bottom-padded-lg">
						<Col className="col-12 text-center">
							<h2 className="db-lead">Frequently Ask Questions</h2>
						</Col>
					</Row>
					<Row>
						<Col className="col-12 col-lg-8 offset-lg-2">
							<h2 className="db-lead text-center mb-4">Service</h2>
							<ul className="db-faq-list">
								<li>
									<h2>What is Buzzem.in?</h2>
									<p>One phone number which rings multiple cell phones simultaneously.Easy to manage and only takes a few minutes to set up.</p>
								</li>
								<li>
									<h2>Will it work for my Building?</h2>
									<p>If your doorbell calls your phone and you press a key to let people in, then YES!</p>
								</li>
								<li>
									<h2>Will I know when a visitor arrives?</h2>
									<p>You'll get a phone call whenever someone arrives and buzz your door. In the future buzzem.in will send text, email, or app notification too whenever someone arrives.</p>
								</li>
								<li>
									<h2>Buzzem.in only work in United State?</h2>
									<p>At the moment yes, only works on United States.</p>
								</li>
							</ul>
							<h2 className="db-lead text-center mb-4">Subscription</h2>
							<ul className="db-faq-list">
								<li>
									<h2>What is the cost of buzzem.in?</h2>
									<p>The cost of the service is $5.99 at month.</p>
								</li>
								<li>
									<h2>What payment methods do you use?</h2>
									<p>You can use any US based credit card to pay the monthly subscription.</p>
								</li>
								<li>
									<h2>Can I cancel my subscription?</h2>
									<p>Yes, you can cancel your subscription anytime. Please note that we don’t do partial refunds for an ongoing subscription.</p>
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>
    </Layout>
  );
};

export default Faq;
