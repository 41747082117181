import React, { useEffect } from 'react';
import Layout from '../components/shared/Layout';
import { Container, Row, Col } from 'react-bootstrap';

const headerImagesQty = 5;

function randomHeaderImage() {
	const headerContainer = document.querySelector("#hero-page");
	if (headerContainer.classList.length === 2) {
		let headerImageClass = "header-img-" + Number(Math.floor(Math.random() * headerImagesQty) + 1);
		headerContainer.classList.add(headerImageClass);
	}
}

const Privacy = () => {

  useEffect(() => {
		randomHeaderImage();
    document.title = "Buzzem.in - Privacy Policy";
  });

  return (
    <Layout>
			<section id="hero-page" className="header-hero header-hero-pages">
				<div className="overlay"></div>
				<Container>
					<div className="db-intro">
						<div className="db-intro-text">
							<div className="db-center-position">
								<h1>Privacy Policy</h1>
							</div>
						</div>
					</div>
				</Container>
			</section>
			<section id="db-faqs">
				<Container>
					<Row>
						<Col className="col-12 col-lg-8 offset-lg-2">

              <p>Updated 8/02/2021</p>
							<h2 className="h2">Our Commitment to Your Privacy</h2>
              <p>Our Privacy Policy tells you what personally identifiable information Buzzem.in may collect from you, how Buzzem.in may process your personally identifiable information, how you can limit our use of your personally identifiable information, and your rights to obtain, modify and/or delete any personally identifiable information Buzzem.in has collected from you.</p>

							<h2 className="h2">Information we Collect</h2>
              <p>1. Information you provide Buzzem.in</p>
              <p>Buzzem.in collects personal information when you request information about our service or otherwise voluntarily provide such information through our app.</p>
              <p>Generally, you will have control over the amount and type of information you provide to us when using our app.</p>
              <p>As a visitor you can browse our app to find out more.</p>
              <p>2. Information Collected Automatically</p>
              <p>When you use our app, we automatically collect certain information by the interaction of your mobile device or web browser with our app.</p>
              <p>3. Cookies</p>
              <p>Like many other apps, we use “Cookies.” A Cookie is a small piece of data stored on your computer or mobile device by our app. We use Cookies to identify the areas of our app that you have visited. We also use cookies to enhance your online experience by eliminating the need to log in multiple times for specific content. Finally, we may use Cookies to personalize the content that you see on our app or to customize marketing and other information we provide to you.</p>
              <p>4. Other Automatically-Gathered Information</p>
              <p>Buzzem.in may automatically record information when you visit its app, including the URL, IP address, browser type and language, and the date and time of your visit. Buzzem.in uses this information to analyze trends among its customers to help improve its app or customize communications and information that you receive from us. If combined with other information we know about you from previous visits, the data possibly could be used to identify you personally, even if you are not signed in to our app.</p>

							<h2 className="h2">How Information is Used</h2>
              <p>When Buzzem.in uses or processes personal data about you, it does so only as necessary to provide the service you use or otherwise with your consent, to comply with applicable law, or to fulfill other legitimate interests of you or us as described in this Policy.  Through our app, you will be provided with the choice of which types of communications you will receive with us, and the ability to change those choices whenever you want.</p>
              <p>1. Information we process with your consent</p>
              <p>Through certain actions when otherwise there is no contractual relationship between us, such as when you browse our app or ask us to provide you more information about our business, you provide your consent to us to process information that may be personally identifiable information.</p>
              <p>Wherever possible, we aim to obtain your explicit consent to process this information, for example, by asking you to agree to our use of Cookies or to receive communications from us.</p>
              <p>We continue to process your information on this basis until you withdraw your consent or it can be reasonably assumed that your consent no longer exists.</p>
              <p>You may withdraw your consent at any time by instructing us using the contact information at the end of this Policy. However, if you do so, you may not be able to use our app further.</p>
              <p>2. Legally Required Releases of Information</p>
              <p>We may be legally required to disclose your personally identifiable information, if such disclosure is (a) required by law, or other legal process; (b) necessary to assist law enforcement officials or government enforcement agencies; (c) necessary to investigate violations of or otherwise enforce our Legal Terms; (d) necessary to protect us from legal action or claims from third parties including you; and/or (e) necessary to protect the legal rights, personal/real property, or personal safety of our company, clients, third party partners, employees, and affiliates.</p>

              <h2 className="h2">Obtaining, Changing or Deleting Your Iinformation</h2>
              <p>1. Access to your personal information</p>
              <p>To obtain a copy of all information Buzzem.in maintains about you, you may send us a request using the contact information at the end of this Policy or, if available, through a tool on our app.  After receiving the request, we will tell you when we expect to provide you with the information, and whether we require any fee for providing it to you.</p>
              <p>2. Remove or Change your Information</p>
              <p>If you wish us to remove or change personally identifiable information that you have provided us, you may contact us at the contact information at the end of this Policy or if available through a tool on our app.</p>
              <p>3. Verification of your Information</p>
              <p>When we receive any request to access, edit or delete personally identifiable information, we will first take reasonable steps to verify your identity before granting you access or otherwise taking any action. This is important to safeguard your information.</p>

              <h2 className="h2">Retention Period for Personal Data</h2>
              <p>Except as otherwise mentioned in this Policy, Buzzem.in keeps your personally identifiable information only for as long as required:</p>
              <p>* to provide you with the services you have requested, or otherwise to perform or enforce a contract between us;</p>
              <p>* to continue to provide the best possible user experience to visitors who return to our app to collect information;</p>
              <p>* to comply with other law, including for any period demanded by tax authorities; or</p>
              <p>* to support a claim or defense in any court or in any legal, regulatory or administrative proceeding.</p>

              <h2 className="h2">Limitation of Liability</h2>
              <p>You assume the sole risk of transmitting your information as it relates to the use of this app, and for any data corruptions, intentional interceptions, intrusions or unauthorized access to information, or of any delays, interruptions to or failures preventing the use this app. In no event shall we be liable for any direct, indirect, special, consequential or monetary damages, including fees, and penalties in connection with your use of materials posted on this app or connectivity to or from this app to any other app.</p>

              <h2 className="h2">Protecting Your Child’s Privacy</h2>
              <p>Even though our app is not designed for use by anyone under the age of 18, we realize that a child may attempt to access the app. We do not knowingly collect personally identifiable information from a child. If you are a parent or guardian and believe your child is using our app, please contact us. We may ask for proof of identification before we remove any information to prevent malicious removal of information. If we discover on our own that a child is accessing our app, we will delete the information as soon as we discover it, we will not use the information for any purpose, and we will not disclose the information to third parties. You acknowledge that we do not verify the age of our users nor do we have any liability to do so. If you are a child, please seek the permission of a parent or guardian before accessing the app.</p>

              <h2 className="h2">Governing Law</h2>
              <p>This Privacy Policy and any claim, controversy or dispute arising under or related to this Privacy Policy shall be governed by and construed in accordance with the internal laws of the state of New York applicable to privacy policies made and to be performed in such jurisdictions without regard to conflicts of law principles thereof.</p>

              <h2 className="h2">Changes to Our Privacy Policy</h2>
              <p>Buzzem.in reserves the right to change this privacy policy at any time. If we decide to change this Privacy Policy, we will post those changes on the app so our users are always aware of what information we collect, use, and disclose. In all cases, your continued use of our app after any change to this Privacy Policy will constitute your acceptance of such change.</p>

              <h2 className="h2">Acceptance</h2>
              <p>By accessing our app, you have willingly accepted the terms of this Privacy Policy.</p>


						</Col>
					</Row>
				</Container>
			</section>
    </Layout>
  );
};

export default Privacy;
