import React, { useState, useEffect, useContext } from 'react';
import { getDashboard, postPhoneNumberSettings } from './DashboardAPI';
import { AppContext } from '../../contexts/AppProvider';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Loading from '../shared/Loading';
import { showNotification } from '../shared/Notification';
import NumberFormat from 'react-number-format'; // Mask input
import loadingLogo from '../../assets/images/buzzem.in-loader.svg';
// Review
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import moment from 'moment';

const Dashboard = () => {
  //const [errors, setErrors] = useState({});
  const [appContext, ] = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isPaid, ] = useState(appContext.payment);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [phoneNumberSettings, setPhoneNumberSettings] = useState({});

  const [accessCodeDateFrom, setAccessCodeDateFrom] = useState("");
  const [accessCodeDateTo, setAccessCodeDateTo] = useState("");
  const [phoneNumberNotification, setPhoneNumberNotification] = useState("");
  const [betweenDates, setBetweenDates] = useState(true);

  const PhoneAcquiredErrorMessage = () => {
    return (
      <span className="h4">The phone number could not be acquired. Please contact user support.</span>
    )
  }

  const AcquiringPhoneMessage = () => {
    return (
      <>
        <img src={loadingLogo} height="25" className="mr-2 mb-2" alt="Acquiring phone number" />
        <span className="h4">Acquiring phone number</span>
      </>
    )
  }

  function checkBetweenDates(fromDate, toDate) {
    if(moment(new Date()).isBetween(fromDate, toDate)) {
      setBetweenDates(true);
    } else {
      setBetweenDates(false);
    }
  }

  useEffect(() => {
    let counter = 0;
    let delay;
    setIsLoading(true);

    document.title = "Buzzem.in - My Phone Number";

    const fetchDashboard = async () => getDashboard().then(data => {
      //console.log("dashboard data: ", data);
      if(data.phoneNumber === "") {
        if(counter >= 5) {
          setPhoneNumberNotification(<PhoneAcquiredErrorMessage />);
          return () => {
            clearTimeout(delay);
          }
        }
        setPhoneNumberNotification(<AcquiringPhoneMessage />);
        delay = setTimeout(() => {
          if(userPhoneNumber === "") {
            counter++;
            //console.log("counter: ", counter);
            fetchDashboard();
          };
        }, 10000);
      } else {
        setUserPhoneNumber(data.phoneNumber);
      }
      setPhoneNumberSettings(data);
      if(data.accessCodeDateFrom !== "") { setAccessCodeDateFrom(data.accessCodeDateFrom) }
      if(data.accessCodeDateTo !== "") { setAccessCodeDateTo(data.accessCodeDateTo) }
      if(data.accessCodeDateFrom !== "" && data.accessCodeDateTo !== "") { checkBetweenDates(data.accessCodeDateFrom, data.accessCodeDateTo) }
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
    })

    if(userPhoneNumber === "") { fetchDashboard(); }

    return () => {
      clearTimeout(delay);
    }
  }, [userPhoneNumber]);

  const formattedPhoneNumber = (phoneNumber) => phoneNumber && phoneNumber.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');

  const slicePlusOne = (phoneNumber) => phoneNumber && phoneNumber.slice(2);

  const cleanPhoneNumber = (phoneNumber) => phoneNumber && phoneNumber.match(/[0-9]/g) && phoneNumber.match(/[0-9]/g).join('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!phoneNumberSettings.accessCodeDisabled) {
      if(phoneNumberSettings.accessCodeDigits === "" || phoneNumberSettings.accessCodeTone === "") {
        showNotification("Warning", "Four Digit Access Code & Tone to open the door fields must be filled.", "warning");
        return false;
      }
      if(phoneNumberSettings.accessCodeDigits.length > 4 || phoneNumberSettings.accessCodeDigits.length < 4 ) {
        showNotification("Warning", "Four Digit Access Code must be 4 digits.", "warning");
        return false;
      }
      if(phoneNumberSettings.accessCodeTone.length > 1 || phoneNumberSettings.accessCodeTone.length < 1 ) {
        showNotification("Warning", "Tone to open the door must be 1 digit.", "warning");
        return false;
      }
      if(phoneNumberSettings.accessCodeDateDisabled === false && (accessCodeDateFrom === "" || accessCodeDateTo === "" )) {
        showNotification("Warning", "Access Code custom date from and to fields must be filled", "warning");
        return false;
      }
      if(phoneNumberSettings.accessCodeDateDisabled === false && (accessCodeDateFrom !== "" && accessCodeDateTo !== "") && (accessCodeDateFrom > accessCodeDateTo)) {
        showNotification("Warning", "Custom date 'from' can't be higher than date 'to'", "warning");
        return false;
      }
    }
    setIsFormLoading(true);
    let payload = {...phoneNumberSettings, accessCodeDateFrom: accessCodeDateFrom, accessCodeDateTo: accessCodeDateTo};
    postPhoneNumberSettings(payload)
      .then((response) => {
        showNotification("Success", "Destinations have been updated", "success");
        //console.log("response: ", response);
        if(payload.accessCodeOneTimeDisabled === true) {
          setPhoneNumberSettings({ ...phoneNumberSettings, accessCodeOneTimeUsed: false });
        }
        if(payload.accessCodeDateDisabled === false) {
          checkBetweenDates(payload.accessCodeDateFrom, payload.accessCodeDateTo);
        }
        setIsFormLoading(false);
      })
      .catch((error) => {
        setIsFormLoading(false);
        if (error.response) {
          console.error(JSON.stringify(error.response.data));
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error(error.message);
        }
        console.log(error.config);
      });
  }

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (name.includes("phone")) {
      value = cleanPhoneNumber(value)
    }

    setPhoneNumberSettings({
      ...phoneNumberSettings,
      [name]: value
    });
  };

  const handlerSwitchAccessCodeDate = (event) => {
    let name = event.target.name;
    let value = event.target.checked;

    if(value === true) {
      //setAccessCodeDateTo("");
      //setAccessCodeDateFrom("");
      setPhoneNumberSettings({
        ...phoneNumberSettings,
        [name]: value,
        accessCodeOneTimeDisabled: true,
        accessCodeDateDisabled: true,
        accessCodeDateFrom: "",
        accessCodeDateTo: ""
      });
    } else {
      setPhoneNumberSettings({
        ...phoneNumberSettings,
        [name]: value
      });
    }
  };

  const handlerSwitchOneTimeUse = (event) => {
    let name = event.target.name;
    let value = event.target.checked;

    if(value === true) {
      setPhoneNumberSettings({
        ...phoneNumberSettings,
        [name]: value
      });
    } else {
      //setAccessCodeDateTo("");
      //setAccessCodeDateFrom("");
      setPhoneNumberSettings({
        ...phoneNumberSettings,
        accessCodeDateDisabled: true,
        [name]: value,
        accessCodeDateFrom: "",
        accessCodeDateTo: ""
      });
    }
  };

  const handlerSwitchCustomDate = (event) => {
    let name = event.target.name;
    let value = event.target.checked;

    if(value === true) {
      //setAccessCodeDateTo("");
      //setAccessCodeDateFrom("");
      setPhoneNumberSettings({
        ...phoneNumberSettings,
        [name]: value,
        accessCodeDateFrom: "",
        accessCodeDateTo: ""
      });
    } else {
      setPhoneNumberSettings({
        ...phoneNumberSettings,
        accessCodeOneTimeDisabled: true,
        [name]: value
      });
    }
  };

  const PhoneNumber = () => {
    return (
      <>
        { userPhoneNumber ? (
          <>
            <h1 className="h1 mb-2">
              <span className="text-danger">&gt;</span> {formattedPhoneNumber(slicePlusOne(userPhoneNumber))}
            </h1>
            <p className="text-danger">
              Please set up this phone number in your building buzzer
            </p>
          </>
        ) : (
          <>
            {phoneNumberNotification}
          </>
        )}
      </>
    )
  }

  return (
    <>
      { isLoading && <Loading/> }
      <Container className="py-4 py-md-5">
        <Row className="mb-3">
          <Col md={12}>
            <PhoneNumber />
          </Col>
        </Row>
        <Row className="mb-5 position-relative">
          {isFormLoading && (
            <Loading/>
          )}
          <Col md={12}>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6} className="mb-4">
                  <h2 className="h1 mb-0">Destinations</h2>
                  <p>Ring these cell phones simultaneously</p>
                  <Row>
                    <Col xs={8}>
                      <Form.Group controlId="phone1">
                        <Form.Label srOnly>Phone Number 1</Form.Label>
                        <NumberFormat
                          type="text"
                          name="phone1"
                          disabled={!isPaid || !phoneNumberSettings.accessCodeDisabled}
                          value={phoneNumberSettings.phone1 || ""}
                          onChange={handleChange}
                          customInput={Form.Control}
                          format="(###) ###-####"
                          allowEmptyFormatting
                          mask="_"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <Form.Group controlId="phone2">
                        <Form.Label srOnly>Phone Number 2</Form.Label>
                        <NumberFormat
                          type="text"
                          name="phone2"
                          disabled={!isPaid || !phoneNumberSettings.accessCodeDisabled}
                          value={phoneNumberSettings.phone2 || ""}
                          onChange={handleChange}
                          customInput={Form.Control}
                          format="(###) ###-####"
                          allowEmptyFormatting
                          mask="_"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <Form.Group controlId="phone3">
                        <Form.Label srOnly>Phone Number 3</Form.Label>
                        <NumberFormat
                          type="text"
                          name="phone3"
                          disabled={!isPaid || !phoneNumberSettings.accessCodeDisabled}
                          value={phoneNumberSettings.phone3 || ""}
                          onChange={handleChange}
                          customInput={Form.Control}
                          format="(###) ###-####"
                          allowEmptyFormatting
                          mask="_"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <Form.Group controlId="phone4">
                        <Form.Label srOnly>Phone Number 4</Form.Label>
                        <NumberFormat
                          type="text"
                          name="phone4"
                          disabled={!isPaid || !phoneNumberSettings.accessCodeDisabled}
                          value={phoneNumberSettings.phone4 || ""}
                          onChange={handleChange}
                          customInput={Form.Control}
                          format="(###) ###-####"
                          allowEmptyFormatting
                          mask="_"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <Form.Group controlId="phone5">
                        <Form.Label srOnly>Phone Number 5</Form.Label>
                        <NumberFormat
                          type="text"
                          name="phone5"
                          disabled={!isPaid || !phoneNumberSettings.accessCodeDisabled}
                          value={phoneNumberSettings.phone5 || ""}
                          onChange={handleChange}
                          customInput={Form.Control}
                          format="(###) ###-####"
                          allowEmptyFormatting
                          mask="_"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className="mb-4">
                  <h2 className="h1 mb-0">Access Code</h2>
                  <p>Ask for a code to open the door</p>
                  <Form.Group controlId="AccessCode">
                    <Form.Switch
                      name="accessCodeDisabled"
                      onChange={handlerSwitchAccessCodeDate}
                      type="switch"
                      label="Enabled"
                      value={phoneNumberSettings.accessCodeDisabled || ""}
                      checked={phoneNumberSettings.accessCodeDisabled ? "checked" : ""}
                      className="inverted-switch"
                    />
                  </Form.Group>

                  <Row>
                    <Col sm={6}>
                      <Form.Group >
                        <Form.Label>Four Digit Access Code</Form.Label>
                        <Row>
                          <Col xs={5} md={7}>
                            <Form.Control
                              name="accessCodeDigits"
                              disabled={phoneNumberSettings.accessCodeDisabled}
                              onChange={handleChange}
                              type="number"
                              value={phoneNumberSettings.accessCodeDigits || ""}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group >
                        <Form.Label>Tone to open the door</Form.Label>
                        <Row>
                          <Col xs={4} md={6} lg={5}>
                            <Form.Control
                              name="accessCodeTone"
                              disabled={phoneNumberSettings.accessCodeDisabled}
                              onChange={handleChange}
                              type="number"
                              value={phoneNumberSettings.accessCodeTone || ""}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </Row>

                  <label><strong>Period of time</strong></label>
                  <Row>
                    <Col className={!phoneNumberSettings.accessCodeOneTimeDisabled && phoneNumberSettings.accessCodeOneTimeUsed ? "bg-danger text-white py-2" : "py-2"}>
                      <Form.Group controlId="OneTimeUse" className="mb-0">
                        <Form.Switch
                          name="accessCodeOneTimeDisabled"
                          disabled={phoneNumberSettings.accessCodeDisabled}
                          onChange={handlerSwitchOneTimeUse}
                          type="switch"
                          label="One Time Use"
                          value={phoneNumberSettings.accessCodeOneTimeDisabled}
                          checked={phoneNumberSettings.accessCodeOneTimeDisabled ? 'checked' : ''}
                          className="inverted-switch"
                        />
                      </Form.Group>
                      {!phoneNumberSettings.accessCodeOneTimeDisabled && phoneNumberSettings.accessCodeOneTimeUsed && (
                        <span className="small">The system already used the code from now will calling all numbers in the list.</span>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col className={!phoneNumberSettings.accessCodeDateDisabled && !betweenDates ? "bg-danger text-white py-2" : "py-2"}>
                      <Form.Group controlId="accessCodeDateDisabled" className="mb-2">
                        <Form.Switch
                          name="accessCodeDateDisabled"
                          disabled={phoneNumberSettings.accessCodeDisabled}
                          onChange={handlerSwitchCustomDate}
                          type="switch"
                          label="Custom Date"
                          value={phoneNumberSettings.accessCodeDateDisabled}
                          checked={phoneNumberSettings.accessCodeDateDisabled ? 'checked' : ''}
                          className="inverted-switch"
                        />
                      </Form.Group>

                      <Row>
                        <Col md={6}>
                          <Form.Group controlId="accessCodeFromDate">
                            <Form.Label>From</Form.Label>
                            <Flatpickr
                              value={ accessCodeDateFrom || "" }
                              name="accessCodeFromDate"
                              disabled={phoneNumberSettings.accessCodeDateDisabled || phoneNumberSettings.accessCodeDisabled}
                              className="form-control"
                              onClose={(_, dateStr) => {
                                if(dateStr) {
                                  setAccessCodeDateFrom(dateStr);
                                } else {
                                  setAccessCodeDateFrom("");
                                }
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="accessCodeToDate">
                            <Form.Label>To</Form.Label>
                            <Flatpickr
                              value={ accessCodeDateTo || "" }
                              name="accessCodeToDate"
                              disabled={phoneNumberSettings.accessCodeDateDisabled || phoneNumberSettings.accessCodeDisabled}
                              className="form-control"
                              onClose={(_, dateStr) => {
                                if(dateStr) {
                                  setAccessCodeDateTo(dateStr);
                                } else {
                                  setAccessCodeDateTo("");
                                }
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {!phoneNumberSettings.accessCodeDateDisabled && !betweenDates && (
                        <span className="small">Please select a valid interval of dates. Now calling all numbers in the list.</span>
                      )}
                    </Col>
                  </Row>

                </Col>
              </Row>
              <Row>
                <Col className="text-right">
                  <Button variant="primary" className="btn-md" type="submit" disabled={isFormLoading || !isPaid || !userPhoneNumber}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Dashboard;
