import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../contexts/AppProvider';
import { postTerms } from './TermsAPI';
import { Container, Row, Col, Button } from 'react-bootstrap';

const SignupTerms = () => {
  const [appContext, setAppContext] = useContext(AppContext);
  // { termsAndConditions: false, billing: false, payment: false, phoneNumber: "" }
  const [isFormLoading, setIsFormLoading] = useState(false);
  const termsPayload = appContext;

  const preparePayload = (termsPayload) => {
    return {
      ...termsPayload,
      termsAndConditions: true
    }
  };

  useEffect(() => {
    document.title = "Buzzem.in - Signup Terms of Use";
  });

  const handleSubmit = (event) => {
    setIsFormLoading(true);
    event.preventDefault();
    //console.log("termsPayload: ", preparePayload(termsPayload) );
    postTerms(preparePayload(termsPayload))
      .then((response) => {
        //console.log("response: ", response);
        setIsFormLoading(false);
        setAppContext({...appContext, ...response});
      })
      .catch((error) => {
        setIsFormLoading(false);
        if (error.response) {
          console.error(JSON.stringify(error.response.data));
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error(error.message);
        }
        console.log(error.config);
      });
  }

  return (
    <Container className="py-4 py-md-5">
      <Row className="mb-5">
        <Col md={12}>
          <h1 className="h1">Terms and Conditions</h1>
          <div className="my-3 border p-3" style={{maxHeight: "300px", overflowY: "scroll"}}>
              <p>Updated 8/02/2021</p>
							<h2 className="h2">Accepting These Terms</h2>
              <p>This document, our rules, policies and the other documents referenced make up our Terms and Conditions (“Terms”). The Terms are a legally binding contract between you and Buzzem.in. This contract sets out your rights and responsibilities when you use our app. Please read them carefully.</p>

              <h2 className="h2">Changes</h2>
              <p>Buzzem.in may amend the Terms at any time by posting a revised version on the app. The revised version will be effective at the time we post it. If we change the Terms in a way that reduces your rights or increases your responsibilities, we will provide you with a notification.</p>

              <h2 className="h2">Communications</h2>
              <p>By creating an Account on Buzzem.in, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by emailing at us, info@buzzem.in.</p>

              <h2 className="h2">Accounts</h2>
              <p>1. When you create an account with Buzzem.in, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.</p>
              <p>2. You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
              <p>3. We reserve the right to refuse service, terminate accounts, remove or edit content at our sole discretion.</p>

              <h2 className="h2">Buzzem.in Pledge</h2>
              <p>We are absolutely committed to maintaining and upgrading our app continuously. As users want new features built in, and augmentations to current systems made, we will do our best to make the changes in a timely manner. Although we cannot guarantee we can make every specific change for our users, we will be constantly making the software better and pledge to do everything we can to make the changes our users want.</p>

              <h2 className="h2">License to Use our Services</h2>
              <p>1. Subject to these Terms, Buzzem.in grants you a limited, personal, non-exclusive, non-transferable license to use of the app for your personal use and not for resale or further distribution. Your right to use the app is limited by all terms and conditions set forth in these Terms.</p>
              <p>2. Except for your pre-existing rights and this license granted to you, we and our licensors retain all right, title and interest in and to the app, including all related intellectual property rights. Buzzem.in is protected by applicable intellectual property laws, including United States copyright law and international treaties.</p>
              <p>3. Except as otherwise explicitly provided in these Terms or as may be expressly permitted by applicable law, you will not, and will not permit or authorize any third party to: (i) reproduce, modify, translate, enhance, decompile, disassemble, reverse engineer or create derivative works of any of our Services; (ii) rent, lease or sublicense access to any of our Services; or (iii) circumvent or disable any security or technological features or measures of our Services.</p>
              <p>4. Any action constituting a violation of this section will result in the immediate cancellation of your account.</p>

              <h2 className="h2">Refunds & Liability</h2>
              <p>1. By accessing and signing up on our app, you acknowledge that Buzzem.in does not issue partial refunds. However, you are at liberty to cancel your use of the app at any given time.</p>
              <p>2. You agree that Buzzem.in shall not be responsible for the wrong use of the service and it further disassociates itself from any eventualities that may occur after the door is opened.</p>

              <h2 className="h2">Feedback</h2>
              <p>Buzzem.in may provide you with a mechanism to provide feedback, suggestions, and ideas, if you choose, about the app ("Feedback"). You agree that we may, in our sole discretion, use the Feedback you provide to us in any way, including in future enhancements and modifications to the app. You hereby grant to us and our assigns a perpetual, worldwide, fully transferable, sub-licensable, irrevocable, royalty free license to use, reproduce, modify, create derivative works from, distribute, and display the Feedback in any manner any for any purpose, without in any media, software, or technology of any kind now existing or developed in the future, without any obligation to provide attribution or compensation to you or any third party.</p>

              <h2 className="h2">Prohibited Uses</h2>
              <p>You may use Buzzem.in only for lawful purposes and in accordance with Terms. You agree not to use the app:</p>
              <p>a. In any way that violates any applicable state, federal or international law or regulation.</p>
              <p>b.	For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</p>
              <p>c.	To impersonate or attempt to impersonate another user.</p>
              <p>d.	In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</p>
              <p>e.	To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the app.</p>

              <h2 className="h2">NO Use by Minors</h2>
              <p>Buzzem.in is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using the app, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the access and usage of the app.</p>

              <h2 className="h2">Third Party Links</h2>
              <p>1. Buzzem.in may contain links to third party sites or services that are not owned or controlled by us.</p>
              <p>2. Buzzem.in has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party sites or services. We do not warrant the offerings of any of these entities/individuals or their sites.</p>
              <p>3. YOU ACKNOWLEDGE AND AGREE THAT BUZZEM.IN SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.</p>

              <h2 className="h2">Disclaimer</h2>
              <p>YOUR USE OF BUZZEM.IN AND THE SERVICE CONTENT IS AT YOUR SOLE RISK. BUZZEM.IN AND THE SERVICE CONTENT EACH ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. BUZZEM.IN EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. WE DO NOT GUARANTEE THE ACCURACY, COMPLETENESS, OR USEFULNESS OF THE SERVICES OR ANY SERVICE CONTENT, AND YOU RELY ON THE SERVICES AND SERVICE CONTENT AT YOUR OWN RISK. ANY MATERIAL THAT YOU ACCESS OR OBTAIN THROUGH THE APP IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY MATERIAL THROUGH OUR SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH OR FROM OUR SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.</p>

              <h2 className="h2">Limitation of Liability</h2>
              <p>BUZZEM.IN WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF THESE DAMAGES), RESULTING FROM YOUR USE OF THE APP. UNDER NO CIRCUMSTANCES WILL THE TOTAL LIABILITY OF US AND LICENSORS OF ALL KINDS ARISING OUT OF OR RELATED TO YOUR USE OF THE APP AND SERVICE CONTENT (INCLUDING BUT NOT LIMITED TO WARRANTY CLAIMS), REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS BASED ON CONTRACT, TORT, OR OTHERWISE, EXCEED THE AMOUNTS, IF ANY, THAT YOU HAVE PAID TO US FOR YOUR USE OF BUZZEM.IN.</p>

              <h2 className="h2">Indemnity</h2>
              <p>You will indemnify and hold Buzzem.in harmless from any costs, damages, expenses, and liability caused by your use of the app, your violation of these Terms, or your violation of any rights of a third party through use of Buzzem.in.</p>

              <h2 className="h2">Termination</h2>
              <p>1. Buzzem.in may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.</p>
              <p>2. If you wish to terminate your account, you may simply discontinue using Service.</p>
              <p>3. All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

              <h2 className="h2">Governing Law</h2>
              <p>1. These Terms shall be governed and construed in accordance with the laws of the state of New York without regard to its conflict of law provisions</p>
              <p>2. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.</p>

              <h2 className="h2">Changes to Service</h2>
              <p>Buzzem.in reserves the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.</p>

              <h2 className="h2">Waiver and Severability</h2>
              <p>1. No waiver by Buzzem.in of any term or condition set forth in the Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Buzzem.in to assert a right or provision under Terms shall not constitute a waiver of such right or provision.</p>
              <p>2. If any provision of the Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.</p>

              <h2 className="h2">Acknowledgement</h2>
              <p>BY USING OUR APP, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS & CONDITIONS AND AGREE TO BE BOUND BY THEM.</p>
          </div>
          <div className="text-right">
            <Button variant="primary" className="btn-md" type="button" onClick={handleSubmit} disabled={isFormLoading}>
              I Agree
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default SignupTerms;
