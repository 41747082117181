import Layout from '../../components/shared/Layout';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { Container, Row, Col } from 'react-bootstrap';

// Blog page
const Foo = () => {
  return(
    <Layout>
      <Helmet>
        <title>Buzzem.in - Keys come with all kinds of security risks, but the right technology can help</title>
        <meta name="description" content="Keyed entry has been common for ages, but it isn't the most secure option. Step into the future of door locks with Buzzem.in." />
        <meta name="keywords" content="Airbnb access, Best security system for Airbnb, roommates" />
      </Helmet>

      <section id="db-faqs">
				<Container>
          <Row>
						<Col className="col-12">
              <p><a href="/blog"> {"<"} Blog</a></p>
						</Col>
					</Row>
					<Row className="row-bottom-padded-lg">
						<Col className="col-12 text-center">
              <h1>Property Keys Aren't Secure — Find the Perfect Modern Alternative</h1>
						</Col>
					</Row>
          <Row>
            <Col>
              <h2>Keys come with all kinds of security risks, but the right technology can help.</h2>
              <p>Whether you are living with roommates, running an Airbnb, operating a bed and breakfast, or you just own an apartment building, chances are that you consider security very important. Protecting your property is essential, even in a nice neighborhood. For years, keys have been considered the standard, but keyed entry can come with its fair share of risks and challenges—especially if you have a lot of people coming and going. Fortunately, modern technology can be used to enhance your property's security and keep your place safe!</p>
              <h2>Is Leaving a Key Outside Actually Safe?</h2>
              <p>Leaving a key outside is a process that is so common that most adults have done it at one point or another in their lives. Chances are that you wouldn't just leave your key in the lock for guests, but do you ever leave it under the doormat? What about in one of your potted plants? Maybe you have even used one of those “super secure” key lockboxes that are shaped like a rock?</p>
              <p>By now, most people consider these practices to be normal, and that is true. A lot of people use these approaches to allow guests and family to enter their homes—but that doesn't make them safe. Leaving a key outside means that anyone can find it, and criminals actually look for these hidden keys.</p>
              <h2>Risks of Leaving a Key for Guests and Tenants</h2>
              <p>In some cases, handing out keys or leaving them outside might seem unavoidable. If you have new roommates or are sharing your property with Airbnb guests, you might think that giving out a key is your only option. This actually isn't the case. Let's explore some of the risks that come from leaving keys with temporary tenants and guests.</p>
              <h3>Break-ins</h3>
              <p>One of the biggest concerns with leaving a key outside or with someone that you don't know is break-ins. When you leave your key outside, criminals can find it and might consider using it to break into your property when you aren't home. In fact, these keys make it easy for them to do so.</p>
              <h3>Secret Duplicates</h3>
              <p>Duplication is a huge point of concern, especially if you're running an Airbnb or bed and breakfast. When you give someone a key to the lock, you cannot guarantee that they won't make a copy. Though you might print “do not duplicate” on the key, that doesn't guarantee it. Some people have the technology to duplicate their own keys. This means that they can come back and rob you or break in long after their stay.</p>
              <h3>Unknown Sharing</h3>
              <p>Most of us assume that when we give someone a key, only they will be using it—but can you really guarantee that? Key sharing is commonly heard of, especially with friends and extended relatives. For all you know, someone who you don't want on your property will be able to enter your space. Whether it is your roommate's friend or a distant cousin, they should not have access to your property unless you want them to.</p>
              <h2>How Buzzem.in Uses Technology to Protect Your Property</h2>
              <p><a href="https://buzzem.in" target="_blank" rel="noreferrer">Buzzem.in</a> is an innovative technology solution aimed at providing simple and effective security for any property. Whether you own an apartment building and want to grant access to your tenants, or you just want a safe and easy way to give your Airbnb guests temporary access, Buzzem.in can help.</p>
              <p>For the longest time, the nicest properties had a doorman who could allow people into the property. <a href="https://buzzem.in" target="_blank" rel="noreferrer">Buzzem.in</a> offers this same convenience using technology instead of an actual person. When someone arrives and requests access, you will receive a notification that will prompt you to give them access to the property. It only unlocks with your approval.</p>
            </Col>
            </Row>
            <Row>
              <Col>
                <div className="video-container widescreen">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/pM3MY-V0Fr4?rel=0" title="Buzzem.in video" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12 text-center">
                <p className='row-top-padded-md row-bottom-padded-md'>
                    <Link to="/login" className="btn btn-primary scroll-btn btn btn-primary">Start using Buzzem.in NOW</Link>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
              <h3>See Every Visitor</h3>
              <p><a href="https://buzzem.in" target="_blank" rel="noreferrer">Buzzem.in</a> is fitted with a feature that offers a complete list of calls. This gives you a record of every single person who has dropped by to visit. You can see who it was, when they were there, and the date and time that they dropped by to request entry. With this feature, you will always know if someone is trying to gain access when they shouldn't.</p>
              <h3>Log Every Arrival</h3>
              <p>In addition to keeping an itemized record of every visitor that drops by, you can also see a record of any time that the door has been opened. Even better, you can see exactly who is at the door so you know whether or not you want to let them in. This feature means that you will always be able to see not only who visits, but also who was allowed to actually enter through the door—and who gave them access!</p>
              <h3>Receive Notifications Right to Your Phone</h3>
              <p>Handing off keys can be difficult, especially if you are dealing with tenants and guests. With <a href="https://buzzem.in" target="_blank" rel="noreferrer">Buzzem.in</a>, notifications are sent directly to your phone. This means that you can grant access to your property from anywhere in the world, making it easy to allow people to enter when they need to. If you don't want access to be given, you can just choose not to accept the request.</p>
              <h3>Add More Users</h3>
              <p>Chances are that you will want more than just one person to have access, especially for the convenience of your Airbnb guests. With <a href="https://buzzem.in" target="_blank" rel="noreferrer">Buzzem.in</a>, you can add new users to the system. This means that when the request comes through, any of the approved parties can grant access. If you want to withdraw access, it is easy. Simply remove the user, and they will no longer be able to give permission to enter the property.</p>
              <h2>The Takeaway</h2>
              <p>Property security is essential to keep your property, your belongings, and your residents safe. While keys served us well for quite a while, the time for new solutions has officially arrived. With <a href="https://buzzem.in" target="_blank" rel="noreferrer">Buzzem.in</a>, you can have an easy, convenient, and secure way to grant access to any property. You will be in complete control, and you don't even have to walk downstairs to let someone in!</p>
            </Col>
          </Row>
          <Row>
              <Col className="col-12 text-center">
                <p className='row-top-padded-md row-bottom-padded-md'>
                    <Link to="/login" className="btn btn-primary scroll-btn btn btn-primary">Start using Buzzem.in NOW</Link>
                </p>
              </Col>
            </Row>
        </Container>
      </section>
    </Layout>
  );
}

export default Foo;