import Layout from '../../components/shared/Layout';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { Container, Row, Col } from 'react-bootstrap';

// Blog page
const Foo = () => {
  return(
    <Layout>
      <Helmet>
        <title>Buzzem.in - Back to Class: How to Share Virtual Keys With Your Friends and Roommates</title>
        <meta name="description" content="Keyed entry has been common for ages, but it isn't the most secure option. Step into the future of door locks with Buzzem.in." />
        <meta name="keywords" content="living with roommates, virtual keys, friends, roommates, apartment building intercom system " />
      </Helmet>

      <section id="db-faqs">
				<Container>
          <Row>
						<Col className="col-12">
              <p><a href="/blog"> {"<"} Blog</a></p>
						</Col>
					</Row>
					<Row className="row-bottom-padded-lg">
						<Col className="col-12 text-center">
              <h1>Back to Class: How to Share Virtual Keys With Your Friends and Roommates</h1>
						</Col>
					</Row>
          <Row>
            <Col>
              <h2>Turn All That Buzzing Into a Virtual Key In Your Control.</h2>
              <p>If you live with multiple people in a building, you know it can get old every time someone buzzes your apartment building intercom system, or maybe you're watching a movie and don't hear when someone is at your building. Additionally, if you don't have a doorman, your cell phone rings, which can sometimes be useful, but can easily be excessive if you live with people who love ordering takeout or are huge online shoppers.</p>
              <p>With <a href="https://buzzem.in" target="_blank" rel="noreferrer">Buzzem.in</a>, you can control how often you want to be alerted, or even set up one number that rings multiple cell phones at once.</p>
              <h2>So How Does It Work?</h2>
              <p><a href="https://buzzem.in" target="_blank" rel="noreferrer">Buzzem.in</a> is a cloud-based system that allows you to have a more customizable way to be alerted when someone buzzes the door. It's easy to set up and use between your roommates for the mailmen, friends, family, or pizza delivery.</p>
              <h2>Easy Setup</h2>
              <p>If you're imagining having to hire a technician to reprogram the intercom, or having to use a bunch of tools to configure anything, think again. All you need to do is <a href="https://buzzem.in/app" target="_blank" rel="noreferrer">create an account on the website</a> and set up the number you want to use, which is then sent to the building superintendent who links the number to the building intercom.</p>
              <p>This phone number can reach multiple phones simultaneously, and alerts can be switched between you and your roommates at any time. You can also just receive notifications when someone buzzes the intercom if you need absolute silence but don't want to miss when your friend arrives.</p>
              <p>If you often have a lot of guests or deliveries, another feature you can set up is access codes.</p>
            </Col>
            </Row>
            <Row>
              <Col className="col-12 text-center">
                <p className='row-top-padded-md row-bottom-padded-md'>
                    <Link to="/login" className="btn btn-primary scroll-btn btn btn-primary">Start using Buzzem.in NOW</Link>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
              <h2>Access Codes</h2>
                <p>Have you ever been expecting guests but sometimes work late or worry your guest will arrive before you do? Now, you can set up a voice access code. Simply speak your access code into the intercom's microphone, and then send the access code to your friends. Buzzem.in recognizes over 50 languages and accents.</p>
                <p>If you have a babysitter or dog walker who comes often, you can set up an access code for them so you don't have to be alerted every time they arrive at the building. This also makes hosting a party much easier, so you can focus on hosting instead of going back and forth to the door.</p>
              <h2>Having Virtual Keys Is the Key</h2>
                <p>Whether you and your roommates are all busy people, or you have a roommate that sometimes forgets their keys, Buzzem.in lets you be your own doorman, without the constant buzzing. This app is also useful for those who are <a href="https://www.washington.edu/doit/deaf-or-hard-hearing" target="_blank" rel="noreferrer">hard of hearing</a> or are sensitive to noises such as a buzzing intercom and prefer to be called on their phone where they can control the volume, or receive notifications.</p>
                <p>You can also view a list of who came to the door and at what times and view who opened the door. Use <a href="https://buzzem.in" target="_blank" rel="noreferrer">Buzzem.in</a> to up to 5 destinations, and transform the door answering game.</p>
            </Col>
          </Row>
          <Row>
              <Col className="col-12 text-center">
                <p className='row-top-padded-md row-bottom-padded-md'>
                    <Link to="/login" className="btn btn-primary scroll-btn btn btn-primary">Start using Buzzem.in NOW</Link>
                </p>
              </Col>
            </Row>
        </Container>
      </section>
    </Layout>
  );
}

export default Foo;