import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import Layout from '../components/shared/Layout';
import { Image, Container, Row, Col } from 'react-bootstrap';
import logo from '../assets/images/roommates.jpeg';


//# Blog Index
const Blog = () => {
  return(
    <Layout>
      <Helmet>
        <title>Buzzem.in - Blog</title>
        <meta name="description" content="Buzzem.in blog interesting ideas on how use the product." />
        <meta name="keywords" content="buzzem.in, blog, posts, apartment intercom system" />
      </Helmet>
      <section id="db-faqs">
				<Container>
          <Row>
						<Col className="col-12 text-center">
              <h1>Buzzem.in Blog</h1>
						</Col>
					</Row>
          <Row>
            <Col className="col-md-12">
              <div className="blog-entry ftco-animate d-md-flex fadeInUp ftco-animated">
                <Link to="/blog/back-to-class-how-to-share-virtual-keys-with-your-friends-roommates">
                  <Image src={logo} roundedCircle="true" loading="lazy" alt="Roommates" width="150" height="150"></Image>
                </Link>
                <div className="text text-2 pl-md-4">
                  <h3 className="mb-2"><Link to="/blog/back-to-class-how-to-share-virtual-keys-with-your-friends-roommates">Back to Class: How to Share Virtual Keys With Your Friends and Roommates</Link></h3>
                  <div className="meta-wrap">
                    <p className="meta">
                      <span><i className="icon-calendar mr-2"></i>September 23, 2022</span>
                    </p>
                  </div>
                  <p className="mb-4">
                  Buzzem.in is a cloud-based system that allows you to have a more customizable way to be alerted when someone buzzes the door. It's easy to set up and use between your roommates for the mailmen, friends, family, or pizza delivery.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-12">
              <div className="blog-entry ftco-animate d-md-flex fadeInUp ftco-animated">
                <Link to="/blog/improve-airbnb-security-in-three-simple-steps">
                  <Image src='https://buzzem.in/static/media/img2.95ba746f.jpg' roundedCircle="true" loading="lazy" alt="Buildings" width="150" height="150"></Image>
                </Link>
                <div className="text text-2 pl-md-4">
                  <h3 className="mb-2"><Link to="/blog/improve-airbnb-security-in-three-simple-steps">Improve Airbnb Security in Three Simple Steps</Link></h3>
                  <div className="meta-wrap">
                    <p className="meta">
                      <span><i className="icon-calendar mr-2"></i>August 2, 2022</span>
                    </p>
                  </div>
                  <p className="mb-4">
                    With so many different people coming in and out of your property, offering easy access for your guests can quickly compromise Airbnb security.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-12">
              <div className="blog-entry ftco-animate d-md-flex fadeInUp ftco-animated">
                <Link to="/blog/property-keys-arent-secure-find-the-perfect-modern-alternative">
                  <Image src='https://buzzem.in/static/media/building1.fa0c744d.jpg' roundedCircle="true" loading="lazy" alt="Buildings" width="150" height="150"></Image>
                </Link>
                <div className="text text-2 pl-md-4">
                  <h3 className="mb-2"><Link to="/blog/property-keys-arent-secure-find-the-perfect-modern-alternative">Property Keys Aren't Secure — Find the Perfect Modern Alternative</Link></h3>
                  <div className="meta-wrap">
                    <p className="meta">
                      <span><i className="icon-calendar mr-2"></i>August 3, 2022</span>
                    </p>
                  </div>
                  <p className="mb-4">
                  Whether you are living with roommates, running an Airbnb, operating a bed and breakfast, or you just own an apartment building, chances are that you consider security very important.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}

export default Blog;



