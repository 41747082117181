import React, { useContext } from 'react';
import { AppContext } from '../../contexts/AppProvider';
import PaymentForm from './PaymentForm';
import { Container, Row, Col } from 'react-bootstrap';

const Billing = () => {
  const [appContext, ] = useContext(AppContext);
  // { termsAndConditions: false, billing: false, payment: false, phoneNumber: "" }

  const products = [
    {
      key: process.env.REACT_APP_STRIPE_PRODUCT_ID,
      price: '$5.99',
      name: 'Basic Plan',
      interval: 'month',
      billed: 'monthly',
    }
  ];

  return (
    <Container className="py-4 py-md-5">
      <Row className="mb-5">
        <Col md={12}>
          <h1 className="h1">Billing Form</h1>
          <PaymentForm
            productSelected={products[0]}
            customer={appContext.email}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default Billing;
