import Layout from '../../components/shared/Layout';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { Container, Row, Col } from 'react-bootstrap';

// Blog page
const Foo = () => {
  return(
    <Layout>
      <Helmet>
        <title>Buzzem.in - Improve Airbnb Security in Three Simple Steps</title>
        <meta name="description" content="How to improve Airbnb Security in Three Simple Steps in a few simple steps." />
        <meta name="keywords" content="Airbnb access, Best security system for Airbnb, apartment intercom system" />
      </Helmet>

      <section id="db-faqs">
				<Container>
          <Row>
						<Col className="col-12">
              <p><a href="/blog"> {"<"} Blog</a></p>
						</Col>
					</Row>
					<Row className="row-bottom-padded-lg">
						<Col className="col-12 text-center">
              <h1>Improve Airbnb Security in Three Simple Steps</h1>
						</Col>
					</Row>
          <Row>
            <Col>
              <p><a href="https://www.airbnb.co.uk/resources/hosting-homes/a/why-host-on-airbnb-2" target="_blank" rel="noreferrer">Renting out your home to travelers</a> is an excellent way to make extra cash fast and can even cover your travel costs when you go away. But with so many different people coming in and out of your property, offering easy access for your guests can quickly compromise Airbnb security. Thankfully, there are plenty of ways you can protect your property without losing out on those lucrative short-term rental profits.</p>
              <p>Here we take a look at three simple steps you can take today to improve your Airbnb security and enjoy enhanced peace of mind.</p>
              <h2>Use a Secure Apartment Intercom System</h2>
              <p>Have you ever wondered what the best security system for Airbnb owners is? An apartment intercom system that is connected to multiple phone numbers is the safest way to arrange access for your guests.</p>
              <p>Keys left in lock boxes can be stolen or duplicated, while neighbors are often unreliable. <a href="https://buzzem.in" target="_blank" rel="noreferrer">An innovative new intercom system</a> known as Buzzem.in takes all the hassle out of guest check-in while keeping your property safe from unwanted intruders.</p>
              <p>If your Airbnb is part of a property complex, you probably already have an intercom system. However, these often outdated arrangements only allow for one connected phone number, making it difficult to ensure your guests can check in smoothly on arrival. Buzzem.in can be implemented into your current system to ring multiple cell phones simultaneously, allowing for easy Airbnb access without putting your property in jeopardy.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="video-container widescreen">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/pM3MY-V0Fr4?rel=0" title="Buzzem.in video" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12 text-center">
                <p className='row-top-padded-md row-bottom-padded-md'>
                    <Link to="/login" className="btn btn-primary scroll-btn btn btn-primary">Start using Buzzem.in NOW</Link>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
              <h2>Go Completely Key-Free</h2>
              <p>If you can, it's best to avoid physical keys when it comes to Airbnb access, as they can often be lost, damaged, or copied, which creates a headache for you and your guests while compromising Airbnb security. Meanwhile, a lockbox is incredibly fiddly, especially when it comes to changing the code between guests, and can freeze up in the winter. Coded locks for your door and advanced intercom systems for building access are the most recommended solution.</p>
              <h2>Screen for Problem Guests</h2>
              <p>Once you have installed Buzzem.in and taken keys out of the equation, your apartment is already much more secure than it was before! The final precautionary step you can take is to <a href="https://www.ruebarue.com/blog/how-to-screen-airbnb-guests" target="_blank" rel="noreferrer">screen potential guests</a> before they stay.</p>
              <p>While this step can be a little time-consuming, it will be worth it in the long run! Simply take a moment to check their reviews, ensure their profile is complete and ask them <a href="https://airhostacademy.com/best-questions-to-ask-an-airbnb-guest-no-reviews/" target="_blank" rel="noreferrer">questions about their stay</a> to find out what you need to know.</p>              
              <h2>Improve Your Airbnb Security Today</h2>
              <p>Sign up for <a href="https://buzzem.in" target="_blank" rel="noreferrer">Buzzem.in</a> today, and enjoy improved Airbnb security straight away.</p>
              <p>We will provide you with a phone number, which you can assign to your apartment intercom as normal. Then, simply log in to your account and add any additional phone numbers you would like to call when the doorbell rings. Simple and secure, you can adjust the numbers on your call list at any time!</p>
            </Col>
          </Row>
          <Row>
              <Col className="col-12 text-center">
                <p className='row-top-padded-md row-bottom-padded-md'>
                    <Link to="/login" className="btn btn-primary scroll-btn btn btn-primary">Start using Buzzem.in NOW</Link>
                </p>
              </Col>
            </Row>
        </Container>
      </section>
    </Layout>
  );
}

export default Foo;