import React from 'react';
import AuthorizedLayout from '../components/shared/AuthorizedLayout';
import AppProvider from "../contexts/AppProvider"
import BillingAccount from '../components/profile/Account';

const Account = () => {
  return (
    <AuthorizedLayout>
      <AppProvider>
        <BillingAccount/>
      </AppProvider>
    </AuthorizedLayout>
  )
}

export default Account;
