import React from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import App from './pages/App';
import Profile from './components/profile/Profile';
import Account from './pages/Account';
import Landing from './pages/Landing';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Faq from './pages/Faq';
import NotFound from './pages/NotFound';
import withTracker from './withTracker';
import Blog from './pages/Blog';
import PostOne from './pages/posts/PostOne';
import PostTwo from './pages/posts/PostTwo';
import PostThree from './pages/posts/PostThree';

const Router = () => {
  
    const history = useHistory();
    const location = useLocation();

    if (location.hash.startsWith('#/')) {
      history.replace(location.hash.replace('#/', '')) // or history.replace
    }

  return (
    <Switch>
      <Redirect from='/login' to='/app' />
      <Route path='/app' component={withTracker(App)} />
      <Route path='/profile' component={withTracker(Profile)} />
      <Route path='/billing' component={withTracker(Account)} />
      <Route exact path='/' component={withTracker(Landing)} />
      <Route path={['/terms', '/#/terms']} component={withTracker(Terms)} />
      <Route path={['/privacy', '/#/privacy']} component={withTracker(Privacy)} />
      <Route exact path='/faq' component={withTracker(Faq)} />
      <Route exact path='/blog' component={withTracker(Blog)} />
      <Route path='/blog/improve-airbnb-security-in-three-simple-steps' component={withTracker(PostOne)} />
      <Route path='/blog/property-keys-arent-secure-find-the-perfect-modern-alternative' component={withTracker(PostTwo)} />
      <Route path='/blog/back-to-class-how-to-share-virtual-keys-with-your-friends-roommates' component={withTracker(PostThree)} />
      <Route component={NotFound} />
    </Switch>
    );
  }

export default Router;