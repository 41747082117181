import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/shared/Layout';
import building1 from '../assets/images/building1.jpg';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { showNotification } from '../components/shared/Notification';
import { Power, Share, Clock, Check, ChevronDown } from 'react-feather';
import {Helmet} from "react-helmet";

function scrollToId(e,element) {
	if (e !== false) {
		e.preventDefault();
	}
  let anchor = document.querySelector(element);
  let elementPosition = anchor.offsetTop;
  let offsetPosition = elementPosition;
  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
};

const headerImagesQty = 5;

function randomHeaderImage() {
	const headerContainer = document.querySelector("#home");
	if (headerContainer.classList.length === 1) {
		let headerImageClass = "header-img-" + Number(Math.floor(Math.random() * headerImagesQty) + 1);
		headerContainer.classList.add(headerImageClass);
	}
}

export const postEmail = async (payload) => {
  axios.post(process.env.REACT_APP_API_URL + '/emails', payload)
  .then(function (response) {
    //console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
};

const EmailForm = () => {
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [isFormLoading, setIsFormLoading] = useState(false);

  const handleSubmit = (event) => {
    setIsFormLoading(true);
		event.preventDefault();
    postEmail({fullName, email, message})
      .then(res => {
        setIsFormLoading(false);
        setFullName("");
        setEmail("");
        setMessage("");
        showNotification("Success", "Email has been sent successfully", "success");
      })
      .catch((error) => {
        setIsFormLoading(false);
        showNotification("Error", "Email has not been sent. Try again later.", "danger");
        console.log("error:", error);
			})
  }

  return (
    <Form action="#" method="post" id="messageForm" onSubmit={handleSubmit}>
      <div className="contact_input_area">
        <Row>
          <Col md={12}>
            <Form.Group controlId="name">
              <Form.Label srOnly>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                placeholder="Full Name"
								value={fullName || ""}
                required
                onChange={(event) => (setFullName(event.target.value))}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group controlId="email">
              <Form.Label srOnly>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="E-mail"
								value={email || ""}
                required
                onChange={(event) => (setEmail(event.target.value))}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group controlId="message">
              <Form.Label srOnly>Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                cols="30"
                rows="4"
                placeholder="Message *"
								value={message || ""}
                required
                onChange={(event) => (setMessage(event.target.value))}>
              </Form.Control>
            </Form.Group>
          </Col>
          <div className="col-12">
            <button type="submit" className="btn btn-md btn-primary" disabled={isFormLoading}>Send Message Now</button>
          </div>
        </Row>
      </div>
    </Form>
  )
}

const Landing = () => {

  useEffect(() => {
		randomHeaderImage();
  });

  return (
    <Layout>
      <Helmet>
        <title>Buzzem.in - Share virtual keys with your friends, roommates, or family.</title>
        <meta name="description" content="Make your apartment buzzer smart. Buzzemin guests to your apartment from multiple phones, share access with roommates." />
        <meta name="keywords" content="Airbnb, access, security, share buzzer roommates, smart buzzer" />
      </Helmet>
      <section id="home" className="header-hero">
				<div className="overlay"></div>
				<Container>
					<div className="db-intro">
						<div className="db-intro-text">
							<div className="db-left-position">
								<h1>Total control of your door</h1>
                <h3>Share virtual keys with your friends, roommates, or family</h3>
								<p>
									<Button className="btn btn-primary scroll-btn" onClick={(e) => scrollToId(e,'#how-it-works')}>How it works</Button>
								</p>
							</div>
						</div>
					</div>
				</Container>
				<div className="db-learn-more">
					<Link to="#" className="scroll-btn" onClick={(e) => scrollToId(e,'#how-it-works')}>
						<span className="text">Explore more</span>
						<span className="arrow"><ChevronDown /></span>
					</Link>
				</div>
			</section>
      <section id="how-it-works" className="features">
				<Container>
					<Row className="text-center row-bottom-padded-md">
						<Col className="col-12 col-lg-8 offset-lg-2">
							<h2 className="db-lead">One phone number which rings multiple cell phones simultaneously</h2>
							<p className="db-sub-lead">When you are living in a building where every time somebody buzz your intercom your cellphone rings and that intercom only can accept one phone number the only solution was only you can open the door. But now Buzzem.in can help you.</p>
							<p>Access made simple</p>
              <div className="video-container widescreen">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/8q0MpuZM9gQ?rel=0" title="Buzzem.in video" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
							</div>
						</Col>
					</Row>
					<Row>
						<Col className="col-12 col-md-6 col-lg-4">
							<div className="db-feature">
								<div className="db-icon">
									<Power />
								</div>
								<h3>Easy To Setup</h3>
								<p>
                  Buzzem.in is cloud based and requires no hardware, hammers, drills, wires, or mistakes. All you need to do is create your account you will receive a phone number and give this phone number to setup the building intercom.
                  Just login into your account and setup the phone number you wants to ring when your door call.
                </p>
							</div>
						</Col>
						<Col className="col-12 col-md-6 col-lg-4">
							<div className="db-feature">
								<div className="db-icon">
									<Share />
								</div>
								<h3>Instant Changes</h3>
								<p>Use the app in your phone to instantly change which phones your buzzer rings on. Stop receiving calls while you're on vacation or temporarily add a guest or Airbnb renter.</p>
							</div>
						</Col>
						<Col className="col-12 col-md-6 col-lg-4">
							<div className="db-feature">
								<div className="db-icon">
									<Clock />
								</div>
								<h3>Your personal doorman</h3>
								<p>Buzzem.in put you in control. Set the application to answer buzzer calls for you during a party, or give an access code to your family, friends, dog walker, or cleaning person.</p>
							</div>
						</Col>

					</Row>
				</Container>
			</section>

			<section id="features" className="features-2">
				<Container>
          <Row>
            <Col className="col-12 col-lg-6 order-lg-2">
              <figure className="db-feature-image">
                <img src={building1} loading="lazy" alt="Buildings" width="1000" height="624" />
              </figure>
            </Col>
            <Col className="col-12 col-lg-6">
              <span className="db-label">See Features</span>
              <h2 className="db-lead">Features</h2>
              <div className="db-feature">
                <div className="db-icon"><Check /></div>
                <div className="db-text">
                  <h3>Voice Access Codes</h3>
                  <p>Speak your access code into the intercom's microphone. Recognizes over 50 different accents and languages.</p>
                </div>
              </div>
              <div className="db-feature">
                <div className="db-icon"><Check /></div>
                <div className="db-text">
                  <h3>List of calls</h3>
                  <p>You have complete list of all calls of your door with date and time.</p>
                </div>
              </div>
              <div className="db-feature">
                <div className="db-icon"><Check /></div>
                <div className="db-text">
                  <h3>Check who open the door</h3>
                  <p>You can check in case who open the door.</p>
                </div>
              </div>
              <div className="db-feature">
                <div className="db-icon"><Check /></div>
                <div className="db-text">
                  <h3>Notifications</h3>
                  <p>Get notifications when somebody is buzzing your door.</p>
                </div>
              </div>
            </Col>
          </Row>
				</Container>
			</section>

			<section id="pricing" className="pricing">
				<Container>
					<Row>
						<Col className="col-12 text-center">
							<h2 className="db-lead fadeInUp animated">Pricing</h2>
						</Col>
						<Col className="col-12">
							<div className="price-box">
								<Row>
									<Col className="col-12 col-md-4 col-lg-3 plan-box">
										<h2 className="pricing-plan">Starter</h2>
										<div className="price"><sup className="currency">$</sup>5.99<small>/mo</small></div>
										<p>Basic customer support.</p>
										<Link to="/login" className="btn btn-primary btn-sm">Get started</Link>
										<hr className="d-block d-md-none" />
									</Col>
									<Col className="col-12 col-md-4 col-lg-4 details-box">
										<ul className="pricing-info">
											<li>- 1 Phone number</li>
											<li>- Up to 5 Destinations</li>
											<li>- Access Code</li>
											<li>- Scheduled Access</li>
											<li>- Up to 100 calls</li>
											<li>- Email support</li>
										</ul>
										<hr className="d-block d-md-none" />
									</Col>
									<Col className="col-12 col-md-4 col-lg-5 details-box text-left">
										<p>With no steep contracts, and an opportunity to upgrade anytime, our basic package offers all the features that we’re renowned for.</p>
									</Col>
								</Row>

							</div>
						</Col>
					</Row>
				</Container>
			</section>

			<section id="contact" className="features-2">
				<Container>
          <Row>
            <Col className="col-12 col-lg-6">
              <h2 className="db-lead">Get in touch!</h2>
              <p>Whether you have a question, query, or concern, our team of specialists are just a click away.</p>
              <p>
              	Email: info@buzzem.in
            	</p>
            </Col>
						<Col className="col-12 col-lg-6">
							<EmailForm />
            </Col>
          </Row>
				</Container>
			</section>

    </Layout>
  );
};

export default Landing;
