import React from 'react';
import Layout from '../components/shared/Layout';
import { Container, Row, Col } from 'react-bootstrap';

const NotFound = () => (
  <Layout>
    <section className="py-4">
      <Container>
        <Row>
          <Col className="text-center">
            <h1>404</h1>
            <p>Page not found!</p>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
);

export default NotFound;