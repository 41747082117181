import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { AppContext } from '../../contexts/AppProvider';
import { postCancelSubscription, getPayments } from './AccountAPI';
import { Container, Row, Col, Button, Card, Table } from 'react-bootstrap';
import Loading from '../shared/Loading';
import { showNotification } from '../shared/Notification';
import ReactMiniAlertConfirm from '../shared/AlertConfirm';
import API from '../../utils/api';
import moment from 'moment';

export const getUser = async () => {
  return await API.get('/users');
};

const BillingAccount = () => {
  const [appContext, setAppContext] = useContext(AppContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const { stripeSubscriptionId, billing } = appContext;
  const [paymentIntents, setPaymentIntents] = useState([]);
  const [cancelSubscriptionConfirm, setCancelSubscriptionConfirm] = useState(false);

  useEffect(() => {
    document.title = "Buzzem.in - My Subscription";

    if(billing === undefined) {
      setIsLoading(true);
      getUser().then(data => {
        setAppContext({...appContext, ...data});
        setIsLoading(false);
      }).catch((error) => {
        console.log(error);
        setIsLoading(false);
      })
    }
  // eslint-disable-next-line
  }, [billing, appContext]);

  useEffect(() => {
    setIsTableLoading(true);
    getPayments().then(data => {
      setPaymentIntents(data);
      //console.log("useEffect getPayment: ", data);
      setIsTableLoading(false);
    }).catch((error) => {
      console.log(error);
      setIsTableLoading(false);
    })
  // eslint-disable-next-line
  }, []);

  function handleCancelSubscriptionConfirm() {
    setCancelSubscriptionConfirm(true);
  }

  function cancelSubscription() {
    setCancelSubscriptionConfirm(false);
    setIsLoading(true);
    const dataPayload = {
      subscriptionId: stripeSubscriptionId
    }
    postCancelSubscription(JSON.stringify(dataPayload))
    .then((cancelSubscriptionResponse) => {
      showNotification("Success", "Subscription Cancelled", "success");
      setAppContext({...appContext, billing: false, payment: false});
      setIsLoading(false);
      history.push("/app");
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false);
    })
  }

  const lastPayments = (collection) => (
    collection.map((intent, index) => (
      <tr key={index}>
        <td>{moment(new Date(intent.created)).format("L")}</td>
        <td className="text-nowrap">{intent.status === "succeeded" ? <span className="badge badge-success">{intent.status}</span> : <span className="badge badge-danger">{intent.status}</span>}</td>
        <td className="text-nowrap text-right">{intent.amount}</td>
      </tr>
    ))
  );

  return (
    <>
      { cancelSubscriptionConfirm &&
        <ReactMiniAlertConfirm
          text="Are you sure?"
          ico=""
          callback={ () => cancelSubscription() }
          closeCallback={ () => setTimeout(() => { setCancelSubscriptionConfirm(false); }, 350) }
        />
      }
      <Container className="py-4 py-md-5">
        <h1 className="h1 mb-4">Billing</h1>
        <Row>
          <Col lg={6} className="col-12 mb-4">
            <Card>
              <Card.Body>
                { isLoading && <Loading/> }
                <h2 className="h2">Subscription</h2>
                { billing ? (
                  <>
                    <p>
                      <strong>Plan Basic</strong>:<br/>
                      $5.99
                    </p>
                    <p className="d-none">
                      <strong>Credit card</strong>:<br/>
                      VISA •••• 1234
                    </p>
                    <div className="text-right">
                      <Button className="btn-md" type="button" disabled={isLoading} onClick={() => handleCancelSubscriptionConfirm()}>Cancel subscription</Button>
                    </div>
                  </>
                ) : (
                  <>
                    <p>No active subscription</p>
                    <div className="text-right">
                      <Button className="btn-md" type="button" onClick={() => history.push("/app")}>Subscribe</Button>
                    </div>
                  </>
                ) }
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} className="col-12 mb-4">
            <Card>
              <Card.Body>
                { isTableLoading && <Loading/> }
                <h2 className="h2">Payments</h2>
                <Table>
                  <thead>
                    <tr>
                      <th>Created</th>
                      <th>Status</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lastPayments(paymentIntents)}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BillingAccount;
