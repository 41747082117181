import React, { useContext } from 'react';
import { AppContext } from '../../contexts/AppProvider';
import SignupTerms from '../signupFlow/SignupTerms';
import Billing from '../billing/Billing';
import Dashboard from '../dashboard/Dashboard';

const SignupFlow = () => {
  const [appContext, ] = useContext(AppContext);
  // { termsAndConditions: false, billing: false, payment: false, phoneNumber: "" }

  return (
    <>
      { !appContext.termsAndConditions &&
        <SignupTerms/>
      }

      { appContext.termsAndConditions && !appContext.billing && (
        <Billing/>
      )}

      { appContext.termsAndConditions && appContext.billing && (
        <Dashboard/>
      )}
    </>
  )
}

export default SignupFlow;
