import { API } from 'aws-amplify'

const api = {
  get: (endpoint, options = {}) => API.get('buzzemInApi', endpoint, options),
  post: (endpoint, options = {}) => API.post('buzzemInApi', endpoint, options),
  put: (endpoint, options = {}) => API.put('buzzemInApi', endpoint, options),
  delete: (endpoint, options = {}) => API.del('buzzemInApi', endpoint, options),
}

export default api;
