import React from 'react';
import AuthorizedLayout from '../components/shared/AuthorizedLayout';
import AppProvider from "../contexts/AppProvider"
import AppFlow from '../components/appFlow/AppFlow';

const App = () => {
  window.dataLayer.push({
    event: 'pageview'
  });
  return (
    <AuthorizedLayout>
      <AppProvider>
        <AppFlow/>
      </AppProvider>
    </AuthorizedLayout>
  )
}

export default App;
