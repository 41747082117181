import React, { createContext } from "react";
import { ConfirmSignIn, ForgotPassword, RequireNewPassword, SignIn, VerifyContact, ConfirmSignUp, SignUp, withAuthenticator } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import Header from './Header';
import Footer from './Footer';

import '@aws-amplify/ui/dist/style.css';
import { I18n } from 'aws-amplify';

I18n.setLanguage('en');
const dict = {
  'en': {
    'Email': 'Verify email',
    'Username': 'Email',
    'Enter your username': 'Enter your email'
  }
}
I18n.putVocabularies(dict);

export const AuthenticateContext = createContext();

const authTheme = {
  input: {
    fontSize: "16px",
    fontWeight: "300",
    padding: "12px",
  },
  select: {
    fontSize: "16px",
    fontWeight: "300",
    padding: "12px 20px 12px 12px"
  },
  toast: {
    position: "fixed",
    backgroundColor: "#ff0000",
    top: "15px",
    left: "auto",
    right: "15px",
    width: "auto",
    maxWidth: "360px",
    marginLeft: "15px",
    zIndex: "9999"
  },
  sectionFooterSecondaryContent: {
    lineHeight: "1.25rem"
  },
  button: {
    backgroundColor: "#ff0000",
    borderColor: "#ff0000",
    borderRadius: ".25rem"
  },
  signInButton: {
    boxSizing: "border-box",
    fontFamily: "Roboto, Arial, sans-serif"
  }
}

const AuthorizedLayout = ({ children }) => {
  return(
    <AuthenticateContext.Provider value={Auth}>
      <div className="app">
        <AppHeader/>
        <main>
          {children}
        </main>
        <AppFooter/>
      </div>
    </AuthenticateContext.Provider>
  );
};

export default withAuthenticator(AuthorizedLayout, false, [
  <Header/>,
  <SignIn />,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <ForgotPassword/>,
  <RequireNewPassword/>,
  <SignUp/>,
  <ConfirmSignUp/>,
  <Footer/>
], null, authTheme );
