import React from 'react';
import { Link } from 'react-router-dom';
import appLogo from '../../assets/images/buzzem.in-logo-darkbg.svg';
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';

const Header = () => {
  function handleSelect() {
    let navMenuMobile = document.querySelector("#cs-navbar");
    if (navMenuMobile.classList.contains("show")) {
      document.querySelector(".navbar-toggler").click();
    }
  };
  return (
  <header className="header-area fixed-top">
    <Container fluid>
      <Row className="align-items-center">
        <Col className="col-12">
          <div className="menu_area">
            <Navbar collapseOnSelect={true} expand="lg" variant="dark">
              <Navbar.Brand>
                <Link to="/">
                  <img src={appLogo} className="logo d-inline-block" alt="buzzem.in" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="cs-navbar" />
              <Navbar.Collapse id="cs-navbar">
                <Nav className="ml-auto mr-auto py-2" id="nav">
                  <Link className="nav-link" to="/#home" onClick={() => handleSelect()}>Home</Link>
                  <Link className="nav-link" to="/#how-it-works" onClick={() => handleSelect()}>How it works</Link>
                  <Link className="nav-link" to="/#features" onClick={() => handleSelect()}>Features</Link>
                  <Link className="nav-link" to="/#pricing" onClick={() => handleSelect()}>Pricing</Link>
                  <Link className="nav-link" to="/#contact" onClick={() => handleSelect()}>Contact</Link>
                  <Link className="nav-link" to="/blog" onClick={() => handleSelect()}>Blog</Link>
                </Nav>
                <Nav className="pb-2 pb-lg-0">
                  <Link className="nav-link" to="/faq">FAQ</Link>
                  <Link className="nav-link" to="/login">Login</Link>
                  <Link className="nav-link" to="/login">Signup</Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </Col>
      </Row>
    </Container>
  </header>
)};

export default Header;