import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Instagram, Youtube, Twitter, Facebook } from 'react-feather';
import appleLogo from '../../assets/images/appstore-logo.png';


const today = new Date();

const Footer = () => (
  <footer id="db-footer">
    <Container>
      <Row>
        <Col className="col-12 col-sm-6 col-lg-3 mb-4">
          <div className="db-footer-widget">
            <h3>Company</h3>
            <ul className="db-links">
              <li>
                <Link to="/#how-it-works">How it works</Link>
              </li>
              <li>
                <Link to="/#features">Features</Link>
              </li>
              <li>
                <Link to="/#pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col className="col-12 col-sm-6 col-lg-3 mb-4">
          <div className="db-footer-widget">
            <h3>Support</h3>
            <ul className="db-links">
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="/terms">Terms of Use</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col className="col-12 col-sm-6 col-lg-3 mb-4">
          <div className="db-footer-widget">
            <h3>Contact Us</h3>
            <p>
              info@buzzem.in
              <br />
              New York
            </p>
          </div>
        </Col>
        <Col className="col-12 col-sm-6 col-lg-3 mb-4">
          <div className="db-footer-widget">
            <h3>Follow Us</h3>
            <ul className="db-social">
              <li>
                <a href="https://www.youtube.com/channel/UCc8Q0OQ1zhZ48cLWVl1MWLw" target="_blank" rel="noopener noreferrer">
                  <Youtube />
                  <span className="sr-only">Youtube</span>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/buzzem_in" target="_blank" rel="noopener noreferrer">
                  <Twitter />
                  <span className="sr-only">Twitter</span>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/buzzem.in.app" target="_blank" rel="noopener noreferrer">
                  <Instagram />
                  <span className="sr-only">Instagram</span>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/Buzzem.in/" target="_blank" rel="noopener noreferrer">
                  <Facebook />
                  <span className="sr-only">Facebook</span>
                </a>
              </li>
            </ul>
            <div className="db-footer-widget">
            <h3>Download App</h3>
              <ul className="db-links">
                <li>
                  <a href="https://apps.apple.com/us/app/buzzem-in/id1636035812?itsct=apps_box_link&itscg=30200" target="_blank" rel="noopener noreferrer">
                    <img src={appleLogo} className="d-inline-block" alt="Apple Store" width="180px"/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    <div className="footer-bottom-bar">
      <span>&copy; {today.getFullYear()}. Buzzem.in - All Rights Reserved.</span>
    </div>
  </footer>
);

export default Footer;
