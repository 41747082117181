import React, { useContext } from 'react';
import { AuthenticateContext } from "./AuthorizedLayout";
import { Link } from 'react-router-dom';
import appLogo from '../../assets/images/buzzem.in-logo-darkbg.svg';
import { Container, Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap';

const AppHeader = () => {
  const sessionData = useContext(AuthenticateContext);

  /* function handleSelect() {
    let navMenuMobile = document.querySelector("#cs-navbar");
    if (navMenuMobile.classList.contains("show")) {
      document.querySelector(".navbar-toggler").click();
    }
  }; */
  return (
  <header className="header-area fixed-top">
    <Container fluid>
      <Row className="align-items-center">
        <Col className="col-12">
          <div className="menu_area">
            <Navbar collapseOnSelect={true} expand="lg" variant="dark">
              <Navbar.Brand>
                <Link to="/">
                  <img src={appLogo} className="logo d-inline-block" alt="buzzem.in" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="cs-navbar" />
              { sessionData &&
                <Navbar.Collapse id="cs-navbar">
                  <Nav className="ml-auto py-2">
                    <NavDropdown title={sessionData.user.attributes.email} id="profile-dropdown">
                      <NavDropdown.Item href="/app">Dashboard</NavDropdown.Item>
                      <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                      <NavDropdown.Item href="/billing">Billing</NavDropdown.Item>
                      <NavDropdown.Item href="#" onClick={() => (sessionData.signOut())}>Logout</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              }
            </Navbar>
          </div>
        </Col>
      </Row>
    </Container>
  </header>
)};

export default AppHeader;
