import React, { useState, useContext, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { AppContext } from '../../contexts/AppProvider';
import { postCreateSubscription, postRetryInvoice } from './BillingAPI';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

// https://stripe.com/docs/billing/subscriptions/fixed-price
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
if (!process.env.REACT_APP_STRIPE_KEY) {
  console.error('**Stripe publishable key environment variable not set**');
}

const CheckoutForm = ({ productSelected, customer }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [subscribing, setSubscribing] = useState(false);
  //const [accountInformation, setAccountInformation] = useState(null);
  const [errorToDisplay, setErrorToDisplay] = useState('');
  const [appContext, setAppContext] = useContext(AppContext);
  const { stripeCustomerId } = appContext;

  useEffect(() => {
    document.title = "Buzzem.in - Signup Subscription";
  });

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.payment_succeeded. This webhook endpoint
              // returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );
      throw new Error('Your card was declined.');
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function retryInvoiceWithNewPaymentMethod({ paymentMethodId, invoiceId }) {
    const priceId = productSelected.name.toUpperCase();
    const productId = productSelected.key;
    const dataPayload = {
      customerId: customer.id,
      paymentMethodId: paymentMethodId,
      invoiceId: invoiceId
    }
    postRetryInvoice(JSON.stringify(dataPayload))
      // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the addional details we need.
      .then((result) => {
        return {
          // Use the Stripe 'object' property on the
          // returned result to understand what object is returned.
          invoice: result,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          productId: productId,
          isRetry: true,
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      .then(handlePaymentThatRequiresCustomerAction)
      // No more actions required. Provision your service for the user.
      .then(onSubscriptionComplete)
      .catch((error) => {
        console.log(error);
        // An error has happened. Display the failure to the user here.
        setSubscribing(false);
        setErrorToDisplay(error && error.error && error.error.decline_code);
      })
  }

  function onSubscriptionComplete(result) {
    //console.log("onSubscriptionComplete: ", result);
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    if (result && !result.subscription) {
      const subscription = { id: result.invoice.subscription };
      result.subscription = subscription;
      localStorage.clear();
    }

    if (result.subscription.status === 'active') {
      setAppContext({...appContext, billing: true, payment: true});
    }

    //setAccountInformation(result);

    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }

  function createSubscription({ paymentMethodId, paymentMethod }) {
    const priceId = productSelected.name.toUpperCase();
    const productId = productSelected.key;

    const dataPayload = {
      customerId: stripeCustomerId,
      paymentMethodId: paymentMethodId,
      priceId: priceId,
      productId: productId,
      paymentMethod: paymentMethod
    }
    postCreateSubscription(JSON.stringify(dataPayload))
    // If the card is declined, display an error to the user.
    .then((result) => {
      if (result.error) {
        // The card had an error when trying to attach it to a customer
        throw result;
      }
      return result;
    })
    // Normalize the result to contain the object returned
    // by Stripe. Add the addional details we need.
    .then((result) => {
      return {
        // Use the Stripe 'object' property on the
        // returned result to understand what object is returned.
        subscription: result,
        paymentMethodId: paymentMethodId,
        priceId: productSelected.name,
        productId: productSelected.key
      };
    })
    // Some payment methods require a customer to do additional
    // authentication with their financial institution.
    // Eg: 2FA for cards.
    .then(handlePaymentThatRequiresCustomerAction)
    // If attaching this card to a Customer object succeeds,
    // but attempts to charge the customer fail. You will
    // get a requires_payment_method error.
    .then(handleRequiresPaymentMethod)
    // No more actions required. Provision your service for the user.
    .then(onSubscriptionComplete)
    .catch((error) => {
      // An error has happened. Display the failure to the user here.
      // We utilize the HTML element we created.
      setSubscribing(false);
      setErrorToDisplay(error.message || error.error.decline_code);
    })
  }

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    setSubscribing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // If a previous payment was attempted, get the lastest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus'
    );

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[createPaymentMethod error]', error);
      setSubscribing(false);
      setErrorToDisplay(error && error.message);
      return;
    }
    console.log('[PaymentMethod]', paymentMethod);
    const paymentMethodId = paymentMethod.id;
    if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
      // Update the payment method and retry invoice payment
      const invoiceId = localStorage.getItem('latestInvoiceId');
      retryInvoiceWithNewPaymentMethod({
        paymentMethodId: paymentMethodId,
        invoiceId: invoiceId,
      });
      return;
    }

    // Create the subscription
    createSubscription({
      paymentMethodId: paymentMethodId,
      paymentMethod: paymentMethod
    });

    //console.log("accountInformation: ", accountInformation);

  };

  return (
    <div id="payment-form-container" className="row">
      <div className="col-md-8 col-lg-6">
        <div className="h5 mb-3">
          Enter your card details. <br />
          Your subscription will start now.
        </div>
        <p className="mb-3">
          Total due now <span>{productSelected.price}</span>
        </p>
        <p className="mb-4">
          Subscribing to{' '}
          <strong>{productSelected.name}</strong>
        </p>

        <div className="form-group">
          <label>Full name</label>
          <input
            className="form-control"
            id="name"
            type="text"
            placeholder="First and Last name"
            required
          />
        </div>
        <form id="payment-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Card</label>
            <div
              className="form-control-stripe py-3 px-2"
              id="card-element"
            >
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      fontWeight: '300',
                      color: '#495057',
                      fontFamily: 'Roboto, sans-serif',
                      '::placeholder': {
                        color: '#6c757d',
                      },
                    },
                    invalid: {
                      color: '#d9534f',
                    },
                  },
                }}
              />
            </div>
            <div className="text-danger mt-2" role="alert">
              {errorToDisplay ? errorToDisplay : null}
            </div>
          </div>
          <div className="form-group text-right">
            <button
              id="submit-premium"
              className="btn btn-primary btn-md"
              type="submit"
            >
              <div>{subscribing ? 'Subscribing...' : 'Subscribe'}</div>
            </button>
          </div>
        </form>
      </div>
    </div>
  );

};

const PaymentForm = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);

export default PaymentForm;
